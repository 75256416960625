import React, { FunctionComponent } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTextBadge } from '../../components/IconTextBadge';
import HandStarsIcon from '../../../assets/images/redesignImages/hand-stars-icon.svg';
import Underline from '../../../assets/images/redesignImages/values-underline.svg';
import GridBg from '../../../assets/images/redesignImages/section-grid.svg';
import { CardComponent } from '../../components/CardComponent';
import LightIcon from '../../../assets/images/redesignImages/light-icon.svg';
import HandMoneyIcon from '../../../assets/images/redesignImages/hand-money-icon.svg';
import MagnifierIcon from '../../../assets/images/redesignImages/magnifier-icon.svg';
import LockIcon from '../../../assets/images/redesignImages/lock-icon.svg';

const ValuesSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
        bgcolor: 'background.paper',
        backgroundImage: `url(${GridBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 15,
          width: '100%',
          maxWidth: { xs: '95vw', xl: '1400px' },
        }}>
        <IconTextBadge
          icon={
            <img
              src={HandStarsIcon}
              alt="hand-icon"
              style={{ width: '24px', height: '24px' }}
            />
          }
          text={t('values.badge')}
        />
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          {t('values.title1')}{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '9px',
                backgroundImage: `url(${Underline})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              },
            }}>
            {t('values.title2')}
          </Box>
        </Typography>
      </Stack>
      {/* Cards Grid */}
      <Grid
        container
        spacing={5}
        sx={{
          marginTop: 13,
          justifyContent: 'center',
          width: '100%',
          maxWidth: { xs: '95vw', xl: '1441px' },
        }}>
        <Grid item xs={12} md={6}>
          <CardComponent
            title={t('values.card1.title')}
            text={t('values.card1.description')}
            icon={
              <img
                src={LightIcon}
                alt=""
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            title={t('values.card2.title')}
            text={t('values.card2.description')}
            icon={
              <img
                src={HandMoneyIcon}
                alt=""
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            title={t('values.card3.title')}
            text={t('values.card3.description')}
            icon={
              <img
                src={MagnifierIcon}
                alt=""
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            title={t('values.card4.title')}
            text={t('values.card4.description')}
            icon={
              <img
                src={LockIcon}
                alt=""
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ValuesSection;
