import React, { FunctionComponent } from 'react';
import HeaderSection from './HeaderSection';
import AboutSection from './AboutSection';
import ValuesSection from './ValuesSection';
import TeamSection from './TeamSection';
import FormSection from './FormSection';

const AboutUsPage: FunctionComponent = () => {
  return (
    <>
      <div id="about">
        <HeaderSection />
      </div>
      <AboutSection />
      <ValuesSection />
      <div id="team">
        <TeamSection />
      </div>
      <div id="form">
        <FormSection />
      </div>
    </>
  );
};

export default AboutUsPage;
