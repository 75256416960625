import React, { FunctionComponent } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTextBadge } from '../../components/IconTextBadge';
import DialogIcon from '../../../assets/images/redesignImages/dialog-icon.svg';
import Underline from '../../../assets/images/redesignImages/form-underline.svg';
import GridBg from '../../../assets/images/redesignImages/section-grid.svg';
import FormComponent from './FormComponent';

const FormSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
        bgcolor: 'background.paper',
        backgroundImage: `url(${GridBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 15,
          maxWidth: { xs: '90vw', xl: '1400px' },
        }}>
        <IconTextBadge
          icon={
            <img
              src={DialogIcon}
              alt=""
              style={{ width: '24px', height: '24px' }}
            />
          }
          text={t('contact.badge')}
        />
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          {t('contact.title1')}{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '9px',
                backgroundImage: `url(${Underline})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              },
            }}>
            <Typography
              variant="h3"
              sx={{
                display: 'inline-block',
              }}>
              {t('contact.title2')}
            </Typography>
          </Box>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 16,
            width: '100%',
          }}>
          <FormComponent />
        </Box>
      </Stack>
    </Stack>
  );
};

export default FormSection;
