import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import lightTextLogo from '../../../assets/images/chaincomply-logo-white-text.png';
import darkTextLogo from '../../../assets/images/chaincomply-logo-black-text.png';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

interface NavbarProps {
  themeMode: 'dark' | 'light';
}

const NavBar: React.FC<NavbarProps> = ({ themeMode }) => {
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const appBarColor =
    themeMode === 'dark' ? '#00143D' : theme.palette.common.white;
  const textColor =
    themeMode === 'dark' ? theme.palette.common.white : theme.palette.grey[900];

  const appBarLogo = themeMode === 'dark' ? lightTextLogo : darkTextLogo;

  const navItems = [
    { text: t('navbar.product'), path: '/#product' },
    { text: t('navbar.about'), path: '/about#about' },
    { text: t('navbar.team'), path: '/about#team' },
    { text: t('navbar.button'), path: '/about#form' },
  ];

  const drawer = (
    <List>
      {navItems.map((item) => (
        <ListItemButton
          key={item.text}
          component={HashLink}
          to={item.path}
          smooth>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </List>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: appBarColor,
        color: textColor,
        elevation: 0,
        borderRadius: '20px',
        boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.15)',
        marginTop: 0,
        paddingY: 1,
        paddingX: { xs: 1, md: 4 },
        width: { xs: '90vw', xl: '1400px' },
        left: '50%',
        transform: 'translateX(-50%)',
        top: isScrolled ? '0px' : { xs: '10px', sm: '26px' },
        transition: 'top 100ms ease-in-out',
      }}>
      <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HashLink to="/#product" smooth>
            <img
              src={appBarLogo}
              alt="ChainComply Logo"
              style={{
                height: isMobile ? '40px' : '60px',
              }}
            />
          </HashLink>
        </Box>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => setMobileOpen(!mobileOpen)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={mobileOpen}
              onClose={() => setMobileOpen(false)}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Button
                component={HashLink}
                to="/#product"
                smooth
                color="inherit"
                sx={{
                  fontFamily: 'Satoshi',
                  fontWeight: '500',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}>
                {t('navbar.product')}
              </Button>
              <Button
                component={HashLink}
                to="/about#about"
                smooth
                color="inherit"
                sx={{
                  fontFamily: 'Satoshi',
                  fontWeight: '500',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}>
                {t('navbar.about')}
              </Button>
              <Button
                component={HashLink}
                to="/about#team"
                smooth
                color="inherit"
                sx={{
                  fontFamily: 'Satoshi',
                  fontWeight: '500',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}>
                {t('navbar.team')}
              </Button>
              {/* <Button
                component={Link}
                to="/"
                color="inherit"
                sx={{
                  fontFamily: 'Satoshi',
                  fontWeight: '500',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}>
                Learn
              </Button> */}
            </Box>
            <Box>
              <Button
                component={HashLink}
                to="/about#form"
                smooth
                variant="contained"
                endIcon={<ArrowOutward />}
                disableElevation
                sx={{
                  '&&.MuiButtonBase-root': {
                    borderRadius: '999px !important',
                  },
                  backgroundColor:
                    themeMode === 'dark' ? 'common.white' : 'primary.dark',
                  color: themeMode === 'dark' ? '#00143D' : 'common.white',
                  fontFamily: 'Satoshi',
                  fontWeight: '500',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor:
                      themeMode === 'dark' ? '#00143D' : 'secondary.light',

                    color:
                      themeMode === 'dark' ? 'common.white' : 'primary.dark',
                  },
                }}>
                {t('navbar.button')}
              </Button>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
