import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IconTextBadge } from '../../components/IconTextBadge';
import UsersIcon from '../../../assets/images/redesignImages/users-icon.svg';
import Underline from '../../../assets/images/redesignImages/team-underline.svg';
import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LukaszProfile from '../../../assets/images/redesignImages/lukasz-profile.png';
import PavlinaProfile from '../../../assets/images/redesignImages/pavlina-new-profile.png';
import NikolayProfile from '../../../assets/images/redesignImages/nikolay-profile.png';

interface TeamMemberCardProps {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
  imageSrc: string;
  linkedInUrl: string;
  boxStyle?: object;
}

const TeamMemberCard: FunctionComponent<TeamMemberCardProps> = ({
  firstName,
  lastName,
  role,
  imageSrc,
  linkedInUrl,
  boxStyle,
}) => {
  return (
    <Box sx={{ width: 322, mb: 4 }}>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '322px',
            height: '50%',
            bgcolor: 'primary.light',
            borderRadius: '30px',
            zIndex: 1,
            ...boxStyle,
          }}
        />
        <img
          src={imageSrc}
          alt={`${firstName} ${lastName}`}
          style={{
            width: '322px',
            height: '352px',
            display: 'block',
            borderRadius: '20px',
            zIndex: 2,
            position: 'relative',
          }}
        />
        <Link
          href={linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ position: 'absolute', bottom: 20, left: 20, zIndex: 3 }}>
          <LinkedInIcon sx={{ fontSize: 36, color: 'common.white' }} />
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          mt: 3,
        }}>
        <Typography
          variant="h5"
          sx={{ color: 'primary.main', fontSize: '32px' }}>
          {`${firstName} `}
          <Typography
            variant="h5"
            sx={{
              color: 'text.primary',
              display: 'inline-flex',
              fontSize: '32px',
              fontWeight: '500',
            }}>
            {lastName}
          </Typography>
        </Typography>
        <Chip
          label={role}
          sx={{
            bgcolor: 'grey.300',
            mt: 3,
            fontFamily: 'Satoshi',
            fontWeight: '500',
            fontSize: '16px',
          }}
        />
      </Box>
    </Box>
  );
};

const TeamSection: FunctionComponent = () => {
  const { t } = useTranslation();
  const teamMembers = [
    {
      id: 1,
      firstName: 'Pavlina',
      lastName: 'Pavlova',
      role: 'Operations',
      imageSrc: PavlinaProfile,
      linkedInUrl: 'https://www.linkedin.com/in/pavlina-pavlova/',
      boxStyle: { width: '301px', left: 10 },
    },
    {
      id: 2,
      firstName: 'Lukasz',
      lastName: 'Lukaszewski',
      role: 'Product',
      imageSrc: LukaszProfile,
      linkedInUrl: 'https://www.linkedin.com/in/lukaszlukaszewski',
    },
    {
      id: 3,
      firstName: 'Nikolay',
      lastName: 'Stanev',
      role: 'Technology',
      imageSrc: NikolayProfile,
      linkedInUrl: 'https://www.linkedin.com/in/nikolaystanev/',
    },
  ];

  return (
    <Stack
      sx={{
        bgcolor: 'secondary.light',
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
      }}>
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 15,
          maxWidth: {
            xs: '95vw',
            md: '80vw',
            lg: '900px',
          },
        }}>
        <IconTextBadge
          icon={
            <img
              src={UsersIcon}
              alt=""
              style={{ width: '24px', height: '24px' }}
            />
          }
          text={t('team.badge')}
        />
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          {t('team.title1')}{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '9px',
                backgroundImage: `url(${Underline})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              },
            }}>
            {t('team.title2')}
          </Box>
        </Typography>
        <Box
          sx={{
            width: { xs: '90vw', md: '80vw' },
            marginTop: 13,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            flexWrap: 'wrap',
            gap: 5,
            justifyContent: 'center',
            alignItems: { xs: 'center', md: 'flex-start' },
          }}>
          {teamMembers.map((member) => (
            <TeamMemberCard key={member.id} {...member} />
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};

export default TeamSection;
