import React, { FunctionComponent, ReactNode } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';

interface IconTextProps {
  icon?: ReactNode;
  text: string;
  sx?: SxProps<Theme>;
}

const IconTextBadge: FunctionComponent<IconTextProps> = ({
  icon,
  text,
  sx,
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        width: 'fit-content',
        alignItems: 'center',
        padding: '8px 16px',
        gap: '8px',
        bgcolor: '#E6E6E6',
        borderRadius: '999px',
        border: '0.5px solid #E6E6E6',
        background: '#F9F9F9',
        boxShadow:
          '0px 4px 11px 0px rgba(0, 0, 0, 0.10), 0px 0px 0px 2px rgba(61, 100, 251, 0.40)',
        ...sx,
      }}>
      {icon && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
          }}>
          {icon}
        </Box>
      )}
      <Typography variant="body1" color="primary.main">
        {text}
      </Typography>
    </Box>
  );
};

export default IconTextBadge;
