import { createTheme } from '@mui/material';
// import './variables.scss';
// font-family: 'Clash Display', sans-serif; Medium(500)
// font-family: 'Satoshi', sans-serif; Regular(400) Medium(500) Bold(700)

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: 74,
      fontWeight: 800,
      fontFamily: ['Rebond Grotesque', 'sans-serif'].join(','),
    },
    h2: {
      fontSize: 74,
      fontWeight: 500,
      fontFamily: ['Clash Display', 'sans-serif'].join(','),
    },
    h3: {
      fontSize: 44,
      fontWeight: 800,
      fontFamily: ['Rebond Grotesque', 'sans-serif'].join(','),
    },
    h4: {
      fontSize: 40,
      fontWeight: 800,
      fontFamily: ['Rebond Grotesque', 'sans-serif'].join(','),
    },
    h5: {
      fontSize: 26,
      fontWeight: 700,
      fontFamily: ['Satoshi', 'sans-serif'].join(','),
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 22,
      fontWeight: 400,
      fontFamily: ['Satoshi', 'sans-serif'].join(','),
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 400,
      fontFamily: ['Satoshi', 'sans-serif'].join(','),
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontSize: 20,
      fontWeight: 600,
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#3D64FB',
      light: '#E1E7FF',
      dark: '#0B0335',
    },
    secondary: {
      main: '#1E5BFA',
      light: '#FAFAFA',
      dark: '#070127',
    },
    error: {
      main: '#F04339',
      light: '#FDF3F3',
      dark: '#BD3D33',
    },
    text: {
      primary: '#1E1E1E',
      secondary: '#FFFFFF',
    },
    warning: {
      main: '#F79007',
      light: '#FFFAEB',
      dark: '#B54707',
    },
    info: {
      main: '#0BA5EC',
      light: '#F0F9FF',
      dark: '#016AA2',
    },
    success: {
      main: '#0EB869',
      light: '#EBFDF3',
      dark: '#047A48',
    },
  },
});

const projectTheme = createTheme(
  {
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&:not(.MuiIconButton-root , .MuiTablePagination-menuItem, .MuiButton-text)':
              {
                borderRadius: '10px !important',
                paddingLeft: '16px !important',
                paddingRight: '16px !important',
              },

            '& > a': {
              color: 'white',
              textDecoration: 'none',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'medium',
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '16px',
            fontSize: 18,
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: 'rgb(37, 60, 150)',
            },
          },
          containedSecondary: {
            color: 'white',
          },
          sizeSmall: {
            fontSize: 14,
            padding: '4px 6px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {},
          paper: {
            padding: '16px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.h3.fontWeight,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            padding: '16px',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '10px !important',
          },
        },
      },
      MuiIcon: {
        defaultProps: {
          fontSize: 'small',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTypography: {
        defaultProps: {
          variant: 'body1',
        },
        styleOverrides: {
          root: {
            margin: 0,
            lineHeight: 1.25,
          },
          // default breakpoints at: https://mui.com/material-ui/customization/breakpoints/
          body1: {
            [theme.breakpoints.down('md')]: {
              fontSize: theme.typography.body2.fontSize,
            },
          },
          body2: {
            [theme.breakpoints.down('md')]: { fontSize: 12 },
          },
          h1: {
            [theme.breakpoints.down('md')]: {
              fontSize: theme.typography.h3.fontSize,
            },
          },
          h2: {
            [theme.breakpoints.down('md')]: {
              fontSize: theme.typography.h3.fontSize,
            },
          },
          h3: {
            [theme.breakpoints.down('md')]: {
              fontSize: theme.typography.h4.fontSize,
            },
          },
          h4: {
            [theme.breakpoints.down('md')]: {
              fontSize: theme.typography.h5.fontSize,
            },
          },
        },
      },
    },
  },
  theme,
);

export default projectTheme;
