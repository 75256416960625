import React, { ReactNode } from 'react';

type MailtoProps = {
  className: string;
  email: string;
  subject?: string;
  body?: string;
  children: ReactNode;
};

const Mailto: React.FunctionComponent<MailtoProps> = ({
  email,
  subject = '',
  body = '',
  children,
}) => {
  return (
    <a
      href={`mailto:${email}?subject=${encodeURIComponent(
        subject,
      )}&body=${encodeURIComponent(body)}`}>
      {children}
    </a>
  );
};

export default Mailto;
