import { Box, Stack, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderImage from '../../../assets/images/redesignImages/about-hero-image.png';

const HeaderSection: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        bgcolor: 'white',
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Stack
        direction="column"
        sx={{
          width: '100%',
          maxWidth: { xs: '90vw', xl: '1400px' },
          mt: 10,
          py: 15,
        }}>
        <Typography variant="h1" color="primary.main">
          {t('aboutHeader.title')}
        </Typography>
        <Typography
          variant="h3"
          sx={{ textTransform: 'capitalize', fontWeight: '400', marginTop: 2 }}>
          {t('aboutHeader.subtitle')}
        </Typography>
        <Box
          sx={{
            marginTop: 7,
          }}>
          <img
            src={HeaderImage}
            alt=""
            style={{ width: '100%', margin: 'auto' }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default HeaderSection;
