import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { IconTextBadge } from '../../components/IconTextBadge';
import BuildingsIcon from '../../../assets/images/redesignImages/buildings-icon.svg';
import BankIcon from '../../../assets/images/redesignImages/bank-icon.svg';
import RankingIcon from '../../../assets/images/redesignImages/ranking-icon.svg';
import GridBg from '../../../assets/images/redesignImages/section-grid.svg';
import ArrowUp from '../../../assets/images/redesignImages/mission-arrow-up.svg';
import ArrowDown from '../../../assets/images/redesignImages/mission-arrow-down.svg';
import { CardComponent } from '../../components/CardComponent';
import { useTranslation } from 'react-i18next';

const AboutSection: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        bgcolor: 'secondary.light',
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Stack
        direction="column"
        sx={{
          width: '100%',
          maxWidth: { xs: '90vw', xl: '1400px' },
          py: 15,
        }}>
        <IconTextBadge
          text={t('about.badge')}
          icon={
            <img
              src={BuildingsIcon}
              alt=""
              style={{ width: '24px', height: '24px' }}
            />
          }
        />
        <Typography variant="h3" sx={{ marginTop: 3 }}>
          {t('about.title')}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 4 }}>
          {t('about.subtitle')}
        </Typography>
        {/* Cards Grid */}
        <Grid
          container
          spacing={{ xs: 0, md: 5 }}
          sx={{
            marginTop: 10,
            justifyContent: 'center',
            maxWidth: { xs: '100%', xl: '1400px' },
          }}>
          <Grid item xs={12} md={6} sx={{ marginTop: { xs: 3, md: 10 } }}>
            <CardComponent
              backgroundColor="secondary.main"
              textColor="text.secondary"
              title={t('about.card1.title')}
              text={t('about.card1.text')}
              icon={
                <img
                  src={BankIcon}
                  alt="Shield Icon"
                  style={{ width: '36px', height: '36px' }}
                />
              }
              alignCenter={true}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginTop: { xs: 3, md: 10 } }}>
            <CardComponent
              backgroundColor="secondary.main"
              textColor="text.secondary"
              title={t('about.card2.title')}
              text={t('about.card2.text')}
              icon={
                <img
                  src={RankingIcon}
                  alt="Notebook icon"
                  style={{ width: '36px', height: '36px' }}
                />
              }
              alignCenter={true}
            />
          </Grid>
        </Grid>
        {/* Mission */}
        <Box
          sx={{
            position: 'relative',
            borderRadius: '32px',
            backgroundColor: 'primary.dark',
            color: 'text.secondary',
            textAlign: 'center',
            marginTop: 15,
            py: 13,
            px: { xs: 6, md: 20, xl: 34 },
          }}>
          <img
            src={ArrowDown}
            alt=""
            style={{ position: 'absolute', top: -10, left: 0 }}
          />
          <img
            src={ArrowUp}
            alt=""
            style={{ position: 'absolute', bottom: -10, right: 0 }}
          />
          <Typography variant="h3" fontWeight="700">
            {t('about.card3.title')}{' '}
            <Typography
              variant="h3"
              color="primary.main"
              sx={{
                display: 'inline-block',
                fontWeight: '700',
              }}>
              {t('about.card3.title2')}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: 3, fontWeight: '500' }}>
              {t('about.card3.text1')}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: 3, fontWeight: '500' }}>
              {t('about.card3.text2')}
            </Typography>
          </Typography>
        </Box>
        {/* Why Care? */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            backgroundImage: `url(${GridBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '32px',
            border: '1px solid #E0E0E0',
            boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.05)',
            color: 'text.primary',
            textAlign: 'center',
            marginTop: 3,
            py: 13,
            px: { xs: 6, md: 20, xl: 34 },
          }}>
          <Typography variant="h3" fontWeight="700">
            {t('about.card4.title')}{' '}
            <Typography
              variant="h3"
              color="primary.main"
              sx={{ display: 'inline-block', fontWeight: '700' }}>
              {t('about.card4.title2')}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: 3, fontWeight: '500' }}>
              {t('about.card4.text1')}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: 3, fontWeight: '500' }}>
              {t('about.card4.text2')}{' '}
              <Box component="span" sx={{ color: 'primary.main' }}>
                {t('about.card4.highlight1')}
              </Box>{' '}
              {t('about.card4.text3')}{' '}
              <Box
                component="span"
                sx={{ color: 'primary.main', fontWeight: '500' }}>
                {t('about.card4.highlight2')}
              </Box>{' '}
              {t('about.card4.text4')}
            </Typography>
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default AboutSection;
