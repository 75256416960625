import React, { FunctionComponent } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GridBg from '../../../assets/images/redesignImages/section-grid.svg';
import HandshakeIcon from '../../../assets/images/redesignImages/handshake-icon.svg';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import { HashLink } from 'react-router-hash-link';

const NextStepSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 30,
        bgcolor: 'background.paper',
        backgroundImage: `url(${GridBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 30,
          maxWidth: { xs: '95vw', md: '80vw', lg: '900px' },
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '120px',
            height: '120px',
            padding: '15px 30px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
            borderRadius: '1498.5px',
            border: '0.75px solid #3F68FE',
            background:
              'linear-gradient(180deg, #F9F9F9 0%, rgba(255, 255, 255, 0.00) 100%)',
            boxShadow: '0px 6px 6px 0px rgba(61, 100, 251, 0.04) inset',
          }}>
          <img src={HandshakeIcon} alt="" />
        </Box>
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          {t('nextStep.title1')}{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '9px',
                // backgroundImage: `url(${Underline})`,
                // backgroundSize: '100% 100%',
                // backgroundRepeat: 'no-repeat',
              },
            }}>
            {t('nextStep.title2')}
          </Box>
        </Typography>
        <Button
          component={HashLink}
          to="/about#form"
          variant="contained"
          endIcon={<ArrowOutward />}
          sx={{ marginTop: 3 }}>
          {t('nextStep.button')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default NextStepSection;
