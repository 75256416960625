import React, { FunctionComponent } from 'react';

import HeaderSection from './HeaderSection';
import CountdownSection from './CountdownSection';
import BenefitsSection from './BenefitsSection';
import FeaturesSection from './FeaturesSection';
import WorkflowSection from './WorkflowSection';
import NextStepSection from './NextStepSection';

const MainPage: FunctionComponent = () => {
  return (
    <>
      <div id="product">
        <HeaderSection />
      </div>
      <CountdownSection />
      <div id="benefits">
        <BenefitsSection />
      </div>
      <div id="features">
        <FeaturesSection />
      </div>
      <WorkflowSection />
      <NextStepSection />
    </>
  );
};

export default MainPage;
