import React from 'react';
import AppRoutes from './AppRoutes';
import './App.css';
import './globalStyles.css';
import '../i18n';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import projectTheme from './projectTheme';

function App() {
  return (
    <ThemeProvider theme={projectTheme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
