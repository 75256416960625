import React, { FunctionComponent } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Checkbox from '../../../assets/images/redesignImages/checkbox.svg';
import HeroImage from '../../../assets/images/redesignImages/heroImage.png';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

const HeaderSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        bgcolor: 'secondary.dark',
        color: 'text.secondary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Stack
        sx={{
          textAlign: 'center',
          maxWidth: { xs: '90vw', md: '80vw' },
          marginTop: { xs: 18, md: 24 },
        }}>
        <Typography variant="h1" textTransform="capitalize">
          {t('mainPage.titlePart1')}
        </Typography>
        <Typography variant="h1" textTransform="capitalize">
          <Typography variant="h1" color="primary.main" display="inline-block">
            {t('mainPage.titlePart2')}
          </Typography>
          {t('mainPage.titlePart3')}
        </Typography>
      </Stack>
      <Stack
        sx={{
          direction: 'column',
          maxWidth: { xs: '95vw', md: '50vw', lg: '600px' },
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 4,
        }}>
        <List>
          <ListItem>
            <ListItemIcon>
              {' '}
              <img
                src={Checkbox}
                alt="checkbox"
                style={{ width: 40, height: 40 }}
              />
            </ListItemIcon>
            <ListItemText>{t('mainPage.listItems.item1')}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {' '}
              <img
                src={Checkbox}
                alt="checkbox"
                style={{ width: 40, height: 40 }}
              />
            </ListItemIcon>
            <ListItemText>{t('mainPage.listItems.item2')}</ListItemText>
          </ListItem>
        </List>
      </Stack>

      <Button
        component={HashLink}
        to="/about#form"
        smooth
        variant="contained"
        endIcon={<ArrowOutwardIcon />}
        sx={{ marginTop: 5 }}>
        {t('mainPage.getStartedButton')}
      </Button>
      <Typography
        variant="caption"
        sx={{ marginTop: 1.5, textAlign: 'center' }}>
        {t('mainPage.investigationToolText')}
      </Typography>
      <img src={HeroImage} alt="heroImage" style={{ marginTop: 116 }} />
    </Stack>
  );
};

export default HeaderSection;
