import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GridBg from '../../../assets/images/redesignImages/grid.svg';

type TimeLeft = {
  [key: string]: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

interface CountdownTimerProps {
  targetDate: Date;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        backgroundImage: `url(${GridBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '20px',
        p: 5,
        border: 1,
        borderColor: 'divider',
        position: 'relative',
        overflow: 'hidden',
        width: { xs: '50vw', md: '90vw', xl: '1400px' },
      }}>
      <Grid container justifyContent="center" spacing={4}>
        {Object.keys(timeLeft).map((interval, index, array) => {
          return (
            <Grid
              item
              xs={12}
              md={3}
              key={interval}
              sx={{
                position: 'relative',
                padding: 0,
                [`@media (min-width: 900px)`]: {
                  '&:not(:last-of-type)::after': {
                    content: '""',
                    position: 'absolute',
                    top: 50,
                    right: 0,
                    bottom: 0,
                    width: '1px',
                    height: '98px',
                    bgcolor: 'primary.main',
                  },
                },
              }}>
              <Box sx={{}}>
                <Typography variant="h2" color="primary.main">
                  {timeLeft[interval].toString().padStart(2, '0')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: 'text.primary', mt: 1 }}>
                  {interval.charAt(0).toUpperCase() + interval.slice(1)}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CountdownTimer;
