import React, { FunctionComponent } from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTextBadge } from '../../components/IconTextBadge';
import HandStarsIcon from '../../../assets/images/redesignImages/hand-stars-icon.svg';
import Underline from '../../../assets/images/redesignImages/benefits-underline.svg';
import GridBg from '../../../assets/images/redesignImages/section-grid.svg';
import { CardComponent } from '../../components/CardComponent';
import ShieldIcon from '../../../assets/images/redesignImages/shield-icon.svg';
import NotebookIcon from '../../../assets/images/redesignImages/notebook-icon.svg';
import GraphDownIcon from '../../../assets/images/redesignImages/graph-down-icon.svg';
import PieIcon from '../../../assets/images/redesignImages/pie-icon.svg';
import ArrowDown from '../../../assets/images/redesignImages/benefits-arrow-down.svg';

const BenefitsSection: FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack
      sx={{
        position: 'relative',
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
        bgcolor: 'background.paper',
        backgroundImage: `url(${GridBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      {isMediumUp && (
        <img
          src={ArrowDown}
          alt=""
          style={{ position: 'absolute', top: 244, right: 0 }}
        />
      )}
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 15,
          maxWidth: { xs: '95vw', md: '80vw', lg: '900px' },
        }}>
        <IconTextBadge
          icon={
            <img
              src={HandStarsIcon}
              alt="hand-icon"
              style={{ width: '24px', height: '24px' }}
            />
          }
          text={t('benefits.badge')}
        />
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          {t('benefits.title.part1')}{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '9px',
                backgroundImage: `url(${Underline})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              },
            }}>
            {t('benefits.title.part2')}
          </Box>
        </Typography>
      </Stack>
      {/* Cards Grid */}
      <Grid
        container
        spacing={5}
        sx={{
          marginTop: 13,
          justifyContent: 'center',
          maxWidth: { xs: '95vw', lg: '92.5vw', xl: '1441px' },
        }}>
        <Grid item xs={12} md={6}>
          <CardComponent
            backgroundColor="secondary.main"
            textColor="text.secondary"
            url="https://example.com"
            title={t('benefits.card1.title')}
            text={t('benefits.card1.text')}
            icon={
              <img
                src={ShieldIcon}
                alt="Shield Icon"
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            url="https://example.com"
            title={t('benefits.card2.title')}
            text={t('benefits.card2.text')}
            icon={
              <img
                src={NotebookIcon}
                alt="Notebook icon"
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            url="https://example.com"
            title={t('benefits.card3.title')}
            text={t('benefits.card3.text')}
            icon={
              <img
                src={GraphDownIcon}
                alt="graph down"
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            url="https://example.com"
            title={t('benefits.card4.title')}
            text={t('benefits.card4.text')}
            icon={
              <img
                src={PieIcon}
                alt="Pie icons"
                style={{ width: '36px', height: '36px' }}
              />
            }
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default BenefitsSection;
