import React, { FunctionComponent, useState } from 'react';
import { FieldError, UseFormRegisterReturn, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

interface FormData {
  name: string;
  email: string;
  phone: string;
  organization: string;
  message: string;
  acceptTerms: boolean;
}

const FormComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormData>();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data: FormData) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        'https://submit-form.com/oWkTxGOU',
        requestOptions,
      );
      if (response.ok) {
        setSubmitted(true);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      alert(t('contact.errorMessage'));
    }
  };

  const handleReset = () => {
    reset();
    setSubmitted(false);
  };

  return (
    <Box
      sx={{
        textAlign: 'start',
        p: 5,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '20px',
        background: '#FFF',
        width: '100%',
      }}
      id="contact">
      {submitted ? (
        <Stack
          sx={{
            direction: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant="h5">{t('contact.receivedMessage')}</Typography>
          <Button
            variant="contained"
            onClick={handleReset}
            sx={{ marginTop: 5 }}>
            {t('contact.newRequestButton')}
          </Button>
        </Stack>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <TextFieldSetup
              label={t('contact.inputPlaceholders.name')}
              errorObj={errors.name}
              register={register('name', {
                required: t('contact.inputErrors.nameRequired'),
              })}
            />
            <TextFieldSetup
              label={t('contact.inputPlaceholders.email')}
              errorObj={errors.email}
              register={register('email', {
                required: t('contact.inputErrors.emailRequired'),
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: t('contact.inputErrors.emailValid'),
                },
              })}
            />
            <TextFieldSetup
              label={t('contact.inputPlaceholders.phone')}
              errorObj={errors.phone}
              register={register('phone', {
                required: t('contact.inputErrors.phoneRequired'),
              })}
            />
            <TextFieldSetup
              label={t('contact.inputPlaceholders.organization')}
              errorObj={errors.organization}
              register={register('organization')}
            />
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight="500" fontSize="19px">
                Message
              </Typography>
              <TextField
                label={t('contact.inputPlaceholders.message')}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.message}
                helperText={errors.message?.message || ''}
                {...register('message', {
                  required: t('contact.inputErrors.messageRequired'),
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('acceptTerms', {
                      required: t('contact.inputErrors.acceptTermsRequired'),
                    })}
                    color="secondary"
                    sx={{
                      color: '#CBCBCB',
                      '&.Mui-checked': {
                        color: '#3D64FB',
                      },
                    }}
                  />
                }
                label={
                  <>
                    {t('contact.checkboxLabel')}
                    <Link
                      component={HashLink}
                      to="/policy#policy"
                      smooth
                      underline="hover"
                      color="primary.main"
                      sx={{ p: 1 }}>
                      {t('contact.privacyPolicyLink')}
                    </Link>
                  </>
                }
              />
              {errors.acceptTerms && (
                <Typography color="error">
                  {errors.acceptTerms.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                endIcon={<ArrowOutward />}
                disabled={false}
                sx={{
                  mt: 3,
                  alignSelf: { xs: 'center', md: 'flex-start' },
                }}>
                {t('contact.submitButton')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default FormComponent;

const TextFieldSetup: React.FC<{
  label: string;
  errorObj?: FieldError;
  register: UseFormRegisterReturn;
  multiline?: boolean;
}> = ({ label, errorObj, register, multiline = false }) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography variant="subtitle2" fontWeight="500" fontSize="19px">
        {label}
      </Typography>
      <TextField
        label={label}
        type={multiline ? 'text' : 'text'}
        variant="outlined"
        fullWidth
        margin="normal"
        error={!!errorObj}
        helperText={errorObj?.message || ''}
        {...register}
        multiline={multiline}
        rows={multiline ? 4 : 1}
      />
    </Grid>
  );
};
