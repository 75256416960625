import React, { FunctionComponent } from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTextBadge } from '../../components/IconTextBadge';
import HandStarsIcon from '../../../assets/images/redesignImages/hand-stars-icon.svg';
import Underline from '../../../assets/images/redesignImages/features-underline.svg';
import NotesIcon from '../../../assets/images/redesignImages/notes-icon.svg';
import BillIcon from '../../../assets/images/redesignImages/bill-check-icon.svg';
import ShieldIcon from '../../../assets/images/redesignImages/shield-check-icon.svg';
import DataImage from '../../../assets/images/redesignImages/data-image.svg';
import VisualizationsImage from '../../../assets/images/redesignImages/visualizations-image.png';
import FlagImage from '../../../assets/images/redesignImages/flag-image.svg';
import ScoringImage from '../../../assets/images/redesignImages/scoring-image.svg';
import ReportImage from '../../../assets/images/redesignImages/click-report-image2.svg';
import SystemImage from '../../../assets/images/redesignImages/system-image.svg';
import GridBg from '../../../assets/images/redesignImages/card-bg-grid.svg';

interface ContentBlockProps {
  title: string;
  text: string;
  badgeText: string;
  listItems?: Array<{ text: string; icon?: React.ReactNode }>;
  imageSrc: string;
  reverseOrder?: boolean;
  imageStyle?: React.CSSProperties;
}

const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  text,
  badgeText,
  listItems,
  imageSrc,
  reverseOrder = false,
  imageStyle,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      spacing={{ xs: 0, md: 15 }}
      sx={{
        marginTop: { xs: 9, md: 18 },
        p: { xs: 2, md: 0 },
        textAlign: 'start',
        maxWidth: { xs: '90vw', xl: '1400px' },
        justifyContent: 'center',
        alignItems: 'start',
      }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        order={reverseOrder && isMdUp ? 2 : 1}
        sx={{
          textAlign: { xs: 'center', md: 'start' },
        }}>
        <IconTextBadge text={badgeText} />
        <Typography variant="h4" sx={{ marginTop: 3 }}>
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 3 }}>
          {text}
        </Typography>
        {listItems && (
          <List>
            {listItems.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  marginTop: { xs: 2, md: 5 },
                  pl: 0,
                  textAlign: { xs: 'center', md: 'start' },
                }}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText>{item.text}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        order={reverseOrder && isMdUp ? 1 : 2}
        alignSelf="start"
        sx={{
          marginTop: { xs: 6, md: 0 },
        }}>
        <Box
          sx={{
            position: 'relative',
            borderRadius: '20px',
            bgcolor: '#E0E5FA',
            backgroundImage: `url(${GridBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: { xs: 250, sm: 300, md: 400, lg: 500 },
            height: { xs: 250, sm: 300, md: 400, lg: 500 },
            padding: { xs: 6, lg: 9, xl: 12 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            maxWidth: '95vw',
          }}>
          <img
            src={imageSrc}
            alt=""
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: '100%',
              ...imageStyle,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const FeaturesSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        bgcolor: 'secondary.light',
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
      }}>
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 15,
          maxWidth: { xs: '95vw', md: '80vw', lg: '900px' },
        }}>
        <IconTextBadge
          icon={
            <img
              src={HandStarsIcon}
              alt="hand-icon"
              style={{ width: '24px', height: '24px' }}
            />
          }
          text={t('features.badge')}
        />
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          {t('features.title1')}{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '9px',
                backgroundImage: `url(${Underline})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              },
            }}>
            {t('features.title2')}
          </Box>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginTop: 2,
            maxWidth: { xs: '90vw', md: '50vw', lg: '600px' },
          }}>
          {t('features.subtitle')}
        </Typography>
      </Stack>
      <ContentBlock
        title={t('features.card1.title')}
        text={t('features.card1.subtitle')}
        badgeText={t('features.card1.badge')}
        listItems={[
          {
            text: t('features.card1.text1'),
            icon: (
              <img
                src={NotesIcon}
                alt="notes"
                style={{ width: 36, height: 36 }}
              />
            ),
          },
          {
            text: t('features.card1.text2'),
            icon: (
              <img
                src={BillIcon}
                alt="checkbox"
                style={{ width: 36, height: 36 }}
              />
            ),
          },
          {
            text: t('features.card1.text3'),
            icon: (
              <img
                src={ShieldIcon}
                alt="checkbox"
                style={{ width: 36, height: 36 }}
              />
            ),
          },
        ]}
        imageSrc={DataImage}
        reverseOrder={false}
      />
      <ContentBlock
        title={t('features.card2.title')}
        text={t('features.card2.subtitle')}
        badgeText={t('features.card2.badge')}
        listItems={[
          {
            text: t('features.card2.text1'),
          },
        ]}
        imageSrc={VisualizationsImage}
        reverseOrder={true}
        imageStyle={{
          position: 'absolute',
          width: '475px',
        }}
      />
      <ContentBlock
        title={t('features.card3.title')}
        text={t('features.card3.subtitle')}
        badgeText={t('features.card3.badge')}
        listItems={[
          {
            text: t('features.card3.text1'),
          },
        ]}
        imageSrc={FlagImage}
        reverseOrder={false}
        imageStyle={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          borderRadius: '0 0 20px 0',
          width: '90%',
        }}
      />
      <ContentBlock
        title={t('features.card4.title')}
        text={t('features.card4.subtitle')}
        badgeText={t('features.card4.badge')}
        imageSrc={ScoringImage}
        reverseOrder={true}
        imageStyle={{
          position: 'absolute',
          width: '500px',
        }}
      />
      <ContentBlock
        title={t('features.card5.title')}
        text={t('features.card5.subtitle')}
        badgeText={t('features.card5.badge')}
        imageSrc={ReportImage}
        reverseOrder={false}
        imageStyle={{
          position: 'absolute',
          right: 0,
          bottom: 3,
          borderRadius: '0 20px 20px 0',
          width: '1094px',
        }}
      />
      <ContentBlock
        title={t('features.card6.title')}
        text={t('features.card6.subtitle')}
        badgeText={t('features.card6.badge')}
        imageSrc={SystemImage}
        reverseOrder={true}
        imageStyle={{
          position: 'absolute',
          width: '80%',
        }}
      />
    </Stack>
  );
};

export default FeaturesSection;
