import React, { FunctionComponent } from 'react';
import { Stack } from '@mui/material';
import { NavBar } from '../NavBar';
import { Footer } from '../Footer';

interface LayoutProps {
  children: React.ReactNode;
  themeMode?: 'dark' | 'light';
}

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  themeMode = 'dark',
}) => {
  return (
    <Stack direction="column" gap={0}>
      <NavBar themeMode={themeMode} />
      {children}
      <Footer />
    </Stack>
  );
};

export default Layout;
