import React, { FunctionComponent } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTextBadge } from '../../components/IconTextBadge';
import ClockIcon from '../../../assets/images/redesignImages/clock-icon.svg';
import Underline from '../../../assets/images/redesignImages/mica-regulation-underline.svg';
import { CountdownTimer } from '../../components/CountdownTimer';

const CountdownSection: FunctionComponent = () => {
  const { t } = useTranslation();

  const targetDate = new Date('2024-12-31T23:59:59');

  return (
    <Stack
      sx={{
        bgcolor: 'secondary.light',
        color: 'text.primary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
      }}>
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 15,
          maxWidth: { xs: '95vw', md: '80vw', lg: '900px' },
        }}>
        <IconTextBadge
          icon={
            <img
              src={ClockIcon}
              alt="Clock"
              style={{ width: '24px', height: '24px' }}
            />
          }
          text={t('countdown.badge')}
        />
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          {t('countdown.title1')}{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -14,
                left: 0,
                right: 0,
                height: '9px',
                backgroundImage: `url(${Underline})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              },
            }}>
            {t('countdown.title2')}
          </Box>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginTop: 3,
            maxWidth: { xs: '90vw', md: '70vw', lg: '900px' },
          }}>
          {t('countdown.subtitle')}
        </Typography>
        <Box sx={{ marginTop: 13 }}>
          <CountdownTimer targetDate={targetDate} />
        </Box>
        <Typography
          variant="body1"
          sx={{
            marginTop: 7,
            maxWidth: { xs: '90vw', md: '70vw', lg: '900px' },
          }}>
          {t('countdown.text1')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginTop: 0.5,
            maxWidth: { xs: '90vw', md: '70vw', lg: '900px' },
          }}>
          {t('countdown.text2')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CountdownSection;
