import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AboutUsPage, MainPage, PolicyPage } from './pages';
import { Layout } from './components/Layout';
import { Box } from '@mui/material';

const AppRoutes: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <MainPage />
          </Layout>
        }
      />
      <Route
        path="/about"
        element={
          <Layout themeMode="light">
            <AboutUsPage />
          </Layout>
        }
      />
      <Route
        path="/policy"
        element={
          <Layout themeMode="light">
            <PolicyPage />
          </Layout>
        }
      />
      <Route
        path="*"
        element={
          <Layout themeMode="light">
            <Box sx={{ p: 4 }}>Page not found.</Box>
          </Layout>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
