import React, { FunctionComponent } from 'react';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AcceleratedIcon from '../../../assets/images/bfg-logo.png';
import MemberIcon from '../../../assets/images/fintech-belgium-logo.png';
import Logo from '../../../assets/images/chaincomply-logo-white-text.png';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: 'secondary.light',
        p: { xs: 1, md: 5 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Stack
        direction="column"
        sx={{
          bgcolor: 'primary.dark',
          color: 'text.secondary',
          p: { xs: '15px 25px', sm: '60px 100px' },
          borderRadius: '20px',
          width: { xs: '90vw', xl: '1400px' },
        }}>
        <Grid container justifyContent="space-between" alignItems="start">
          <Grid item xs={12} md={5}>
            <Stack
              direction="column"
              sx={{
                alignItems: { xs: 'center', md: 'start' },
              }}>
              <img src={Logo} alt="ChainComply" style={{ width: ' 207px' }} />
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={3}
                sx={{
                  marginTop: 3,
                  alignItems: { xs: 'center', md: 'start' },
                  textAlign: { xs: 'center', md: 'start' },
                }}>
                <Typography variant="body2" color="inherit" gutterBottom>
                  Member of
                  <img
                    src={MemberIcon}
                    alt="Fintech Belgium"
                    style={{ width: '80px', marginTop: '12px' }}
                  />
                </Typography>
                <Typography variant="body2" color="inherit" gutterBottom>
                  Accelerated by
                  <img
                    src={AcceleratedIcon}
                    alt="Blockchain Founders Group"
                    style={{ width: '80px', marginTop: '12px' }}
                  />
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack
              direction="column"
              sx={{
                mt: { xs: 4, md: 0 },
                alignItems: { xs: 'center', md: 'start' },
              }}>
              <Link
                component={HashLink}
                to="/about#about"
                smooth
                underline="hover"
                color="inherit"
                sx={{ p: 1 }}>
                {t('footer.about')}
              </Link>
              <Link
                component={HashLink}
                to="/about#form"
                smooth
                underline="hover"
                color="inherit"
                sx={{ p: 1 }}>
                {t('footer.contact')}
              </Link>
              <Link
                component={HashLink}
                to="/policy#policy"
                smooth
                underline="hover"
                color="inherit"
                sx={{ p: 1 }}>
                {t('footer.policy')}
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack
              direction="column"
              sx={{
                mb: { xs: 4, md: 0 },
                alignItems: { xs: 'center', md: 'start' },
              }}>
              <Link
                component={HashLink}
                to="/#product"
                smooth
                underline="hover"
                color="inherit"
                sx={{ p: 1 }}>
                {t('footer.product')}
              </Link>
              <Link
                component={HashLink}
                to="/#benefits"
                smooth
                underline="hover"
                color="inherit"
                sx={{ p: 1 }}>
                {t('footer.benefits')}
              </Link>
              <Link
                component={HashLink}
                to="/#features"
                smooth
                underline="hover"
                color="inherit"
                sx={{ p: 1 }}>
                {t('footer.features')}
              </Link>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={1}
            sx={{ textAlign: { xs: 'center', md: 'right' } }}>
            <Link
              href="https://linkedin.com/company/chaincomply"
              target="_blank"
              rel="noopener noreferrer">
              <LinkedInIcon
                sx={{ fontSize: 32, cursor: 'pointer', color: 'common.white' }}
              />
            </Link>
          </Grid>
        </Grid>
        <Box
          sx={{
            borderTop: 1,
            borderColor: 'grey.700',
            mt: 7,
            pt: 5,
            textAlign: { xs: 'center', sm: 'start' },
          }}>
          <Typography
            variant="subtitle2"
            color="inherit"
            fontSize={{ xs: '12px', md: '18px' }}>
            {t('footer.copyright')}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default Footer;
