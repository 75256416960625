import React, { ReactNode } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import ArrowOutward from '@mui/icons-material/ArrowOutward';

interface CardComponentProps {
  backgroundColor?: string;
  textColor?: string;
  url?: string;
  title: string;
  text: string;
  icon: ReactNode;
  buttonText?: string;
  buttonColor?: string;
  alignCenter?: boolean;
  sx?: SxProps<Theme>;
}

const CardComponent: React.FC<CardComponentProps> = ({
  backgroundColor = 'background.paper',
  textColor = 'text.primary',
  url,
  title,
  text,
  icon,
  buttonText,
  buttonColor = 'primary.main',
  alignCenter = false,
  sx,
}) => {
  const cardContentStyles: SxProps<Theme> = {
    textAlign: alignCenter ? 'center' : 'start',
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignCenter ? 'center' : 'start',
  };
  return (
    <Card
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: '20px',
        width: '100%',
        height: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.05)',
        p: 2,
        ...sx,
      }}>
      <CardContent sx={cardContentStyles}>
        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: textColor,
            p: 2,
            borderRadius: '999px',
            border: '1px solid rgba(61, 100, 251, 0.20)',
            background: '#F9F9F9',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.07)',
          }}>
          {icon}
        </Box>
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', color: textColor, mt: 3 }}>
          {title}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: textColor, mt: 2 }}>
          {text}
        </Typography>
        {url && buttonText && (
          <Button
            variant="text"
            endIcon={<ArrowOutward />}
            href={url}
            sx={{
              mt: 2,
              pl: 0,
              color: buttonColor,
              textTransform: 'none',
              '&:hover': {
                textDecoration: 'underline',
                backgroundColor: 'transparent',
              },
              justifyContent: alignCenter ? 'center' : 'flex-start',
            }}>
            {buttonText}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default CardComponent;
