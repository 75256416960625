import React, { FunctionComponent } from 'react';
import {
  Box,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTextBadge } from '../../components/IconTextBadge';
import WorkflowIcon from '../../../assets/images/redesignImages/workflow-icon.svg';
import Underline from '../../../assets/images/redesignImages/works-underline.svg';

interface StepData {
  label: string;
  description: string;
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 30,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 2,
    borderRadius: 1,
    borderColor: '#3D64FB',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 60,
  height: 60,
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  [theme.breakpoints.down('md')]: {
    width: 40,
    height: 40,
    position: 'absolute',
    left: '45%',
    top: 35,
  },
}));

const CustomStepIcon: FunctionComponent<{
  icon: React.ReactNode;
  active?: boolean;
  completed?: boolean;
}> = (props) => {
  return (
    <CustomStepIconRoot>
      <Typography
        variant="h3"
        color="inherit"
        fontSize={{ xs: '16px', md: '28px' }}>
        {props.icon}
      </Typography>
    </CustomStepIconRoot>
  );
};

const CustomStepper: FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const steps: StepData[] = [
    {
      label: t('workflow.step1.label'),
      description: t('workflow.step1.description'),
    },
    {
      label: t('workflow.step2.label'),
      description: t('workflow.step2.description'),
    },
    {
      label: t('workflow.step3.label'),
      description: t('workflow.step3.description'),
    },
    {
      label: t('workflow.step4.label'),
      description: t('workflow.step4.description'),
    },
    {
      label: t('workflow.step5.label'),
      description: t('workflow.step5.description'),
    },
  ];

  return (
    <Stepper
      orientation={matches ? 'vertical' : 'horizontal'}
      alternativeLabel={!matches}
      nonLinear
      connector={<CustomConnector />}
      sx={{
        '& .MuiStepLabel-label.Mui-active': {
          color: 'primary.main',
        },
        '& .MuiStepLabel-label': {
          color: theme.palette.text.secondary,
        },
        '.MuiStepConnector-root': {
          display: { xs: 'none', md: 'block' },
        },
      }}>
      {steps.map((step, index) => (
        <Step key={step.label} sx={{ position: 'relative' }}>
          <StepLabel
            StepIconComponent={(stepProps) => (
              <CustomStepIcon icon={index + 1} {...stepProps} />
            )}>
            <Typography
              variant="h5"
              color="text.secondary"
              fontSize="20px"
              sx={{
                display: 'block',
                mt: { xs: 10, md: 2 },
                textAlign: matches ? 'center' : 'inherit',
              }}>
              {step.label}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: 'block',
                mt: 1,
                width: '216px',
                textAlign: matches ? 'center' : 'inherit',
              }}>
              {step.description}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const WorkflowSection: FunctionComponent = () => {
  return (
    <Stack
      sx={{
        bgcolor: 'secondary.dark',
        color: 'text.secondary',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 20,
      }}>
      <Stack
        sx={{
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 15,
          width: { xs: '95vw', md: '80vw', lg: '900px' },
        }}>
        <IconTextBadge
          icon={
            <img
              src={WorkflowIcon}
              alt="Icon"
              style={{ width: '24px', height: '24px' }}
            />
          }
          text="Workflow"
        />
        <Typography variant="h3" sx={{ marginTop: 4 }}>
          How it{' '}
          <Box
            component="span"
            sx={{
              position: 'relative',
              color: 'primary.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                right: 0,
                height: '9px',
                backgroundImage: `url(${Underline})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              },
            }}>
            Works
          </Box>
        </Typography>
      </Stack>
      <Box sx={{ marginTop: 12 }}>
        <CustomStepper />
      </Box>
    </Stack>
  );
};

export default WorkflowSection;
